<template>
	<div :class="`interviewInfo addHuman ${'app-container'}`">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="toBack">返回</el-button>
			<div class="title">员工录入</div>
		</div>
		<section class="sections-container">
			<el-form ref="form" :model="form" label-width="188px" :rules="rules" v-show="act == 1" class="formIteClass">
				<div class="section-wrap">
					<el-row>
						<el-col :span="12">
							<el-form-item prop="name" label="姓名">
								<el-input v-model="form.name" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="subName" label="曾用名/英文名/花名">
								<el-input v-model="form.subName" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="phoneNumber" label="手机号">
								<el-input v-model="form.areaCode" style="width: 80px"></el-input>
								<el-input style="width: calc(70% - 80px)" v-model="form.phoneNumber" maxlength="11">
									<!-- <el-select style="width: 85px" v-model="form.areaCode" slot="prepend" placeholder="请选择">
										<el-option label="+86" value="86"></el-option>
										<el-option label="+852" value="852"></el-option>
										<el-option label="+853" value="853"></el-option>
										<el-option label="+886" value="886"></el-option>
									</el-select> -->
									<!-- <template slot="prepend"><el-input v-model="form.areaCode"></el-input></template> -->
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="nationality" label="民族">
								<el-select v-model="form.nationality" class="inputWidth" filterable placeholder="请选择民族">
									<el-option v-for="(item, i) in nationalOptions" :key="i" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="maritalStatus" label="婚姻情况">
								<el-select v-model="form.maritalStatus" placeholder="选择婚姻情况" class="inputWidth">
									<el-option
										v-for="(item, i) in maritalStatusList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="politicalStatus" label="政治面貌">
								<el-select v-model="form.politicalStatus" placeholder="选择政治面貌" class="inputWidth">
									<el-option
										v-for="(item, i) in politicalStatusList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="identityNum" label="身份证号">
								<el-input v-model="form.identityNum" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="certificateValidity" label="证件有效期">
								<el-date-picker
									v-model="form.certificateValidity"
									type="daterange"
									style="width: calc(70% - 100px)"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									:disabled="form.longTermEffective"
								>
								</el-date-picker>
								<el-checkbox
									class="el-input-group__append"
									style="padding: 0 0 0 8px; width: 100px; height: 40px"
									v-model="form.longTermEffective"
									@change="() => (form.certificateValidity = '')"
									>长期有效</el-checkbox
								>
								<!-- <el-input placeholder="请输入内容" class="inputWidth" style="" v-model="form.certificateValidity">
									<template slot="append"><el-checkbox v-model="form.certificateValidity">长期有效</el-checkbox></template>
								</el-input> -->
							</el-form-item>
							<!-- <el-form-item prop="certificateValidity" label=""> </el-form-item> -->
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="certificateAddress" label="证件地址">
								<el-input v-model="form.certificateAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="age" label="年龄">
								<el-input v-model="form.age" class="inputWidth" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="birthday" label="出生日期">
								<el-date-picker
									disabled
									v-model="form.birthday"
									type="date"
									class="inputWidth"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="nativePlace" label="籍贯">
								<el-input v-model="form.nativePlace" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="bankCardNo" label="银行卡号">
								<el-input v-model="form2.bankCardNo" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="bankDeposit" label="开户行">
								<el-input v-model="form2.bankDeposit" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="bankBranch" label="开户支行">
								<el-input v-model="form2.bankBranch" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12"> </el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="emergencyContact" label="紧急联络人1">
								<el-input v-model="form.emergencyContact" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="emergencyContactRelation" label="与紧急联络人1的关系">
								<!-- <el-input v-model="form.emergencyContactRelation" class="inputWidth"></el-input> -->
								<el-select class="inputWidth" v-model="form.emergencyContactRelation">
									<el-option
										v-for="(item, index) in relationshipList"
										:key="index"
										:label="item.dictName"
										:value="item.dictId"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="emergencyContactPhone" label="紧急联络人1电话">
								<!-- <el-input v-model="form.emergencyContactPhone" class="inputWidth"></el-input> -->
								<el-input class="input-with-select inputWidth" v-model="form.emergencyContactPhone">
									<!-- <el-select style="width: 85px" v-model="form.areaCode" slot="prepend" placeholder="请选择">
										<el-option label="+86" value="86"></el-option>
										<el-option label="+852" value="852"></el-option>
										<el-option label="+853" value="853"></el-option>
										<el-option label="+886" value="886"></el-option>
									</el-select> -->
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="emergencyContact2" label="紧急联络人2">
								<el-input v-model="form.emergencyContact2" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="emergencyContactRelation2" label="与紧急联络人2的关系">
								<el-input v-model="form.emergencyContactRelation2" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="emergencyContactPhone2" label="紧急联络人2电话">
								<el-input v-model="form.emergencyContactPhone2" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="highestEducation" label="最高学历">
								<el-select v-model="form.highestEducation" placeholder="选择最高学历" class="inputWidth">
									<el-option
										v-for="(item, i) in highestEducationList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<!-- <el-col :span="12">
							<el-form-item prop="mailbox" label="个人邮箱">
								<el-input v-model="form.mailbox" class="inputWidth"></el-input>
							</el-form-item>
						</el-col> -->
						<el-col :span="12">
							<el-form-item prop="homeAddress" label="家庭住址">
								<el-input v-model="form.homeAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>

						<!-- <el-col :span="12">
							<el-form-item prop="registeredResidenceAddress" label="户口所在">
								<el-input v-model="form.registeredResidenceAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col> -->
						<!-- <el-col :span="12">
							<el-form-item prop="workAddress" label="工作地点">
								<el-input v-model="form.workAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col> -->
					</el-row>
					<el-row>
						<el-col :offset="11" style="margin-top: 10px">
							<!-- <el-button @click="$router.back()" v-if="!status">取消</el-button> -->
							<el-button @click="next()">下一步</el-button>
							<el-button type="primary" @click="handleClick">保存</el-button>
						</el-col>
					</el-row>
				</div>
			</el-form>
			<el-form ref="form2" :rules="rules2" :model="form2" label-width="188px" v-show="act == 2" class="formIteClass">
				<div class="section-wrap">
					<el-row>
						<el-col :span="12">
							<el-form-item prop="entryTime" label="入职时间">
								<el-date-picker
									v-model="form2.entryTime"
									type="date"
									class="inputWidth"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									placeholder="选择日期"
									@change="getPlanTime"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="status" label="员工状态">
								<el-select v-model="form2.status" placeholder="选择员工状态" class="inputWidth" @change="statusChange">
									<el-option v-for="(item, i) in staffStatusList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="probationPeriod" label="试用期">
								<el-select v-model="form2.probationPeriod" placeholder="选择试用期" class="inputWidth" @change="getPlanTime">
									<el-option
										v-for="(item, i) in probationPeriodList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
										:disabled="item.disabled"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="planTurnPositiveTime" label="计划转正时间">
								<el-input v-model="form2.planTurnPositiveTime" class="inputWidth" readonly></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="turnPositiveTime" label="实际转正时间">
								<el-date-picker
									v-model="form2.turnPositiveTime"
									type="date"
									class="inputWidth"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="firstWorkTime" label="首次参加工作时间">
								<el-date-picker
									v-model="form2.firstWorkTime"
									type="date"
									class="inputWidth"
									:picker-options="firstWorkTimePickerOptions"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="orgId" label="组织">
								<treeselect
									class="inputWidth"
									sizes="middle"
									:props="depProps"
									:options="deps"
									:value="depsId"
									:clearable="true"
									:accordion="true"
									:level="depsId"
									@getValue="getValue2($event)"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="officeLevel" label="职级">
								<el-checkbox-group v-model="form2.officeLevel" />
								<div class="inputDiv" @click="changejob">{{ form2.officeLevel }}</div>
								<!-- <el-input v-model="form2.officeLevel" class="inputWidth" readonly></el-input> -->
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="positionId" label="职位">
								<!-- <el-checkbox-group v-model="form2.positionId" /> -->
								<div class="inputDiv" @click="changejob">{{ form2.officeName }}</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="officeCode" label="职级代码">
								<el-checkbox-group v-model="form2.officeCode"> </el-checkbox-group>
								<div class="inputDiv" @click="changejob">{{ form2.officeCode }}</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="jobId" label="岗位">
								<el-checkbox-group v-model="form2.jobId" />
								<div class="inputDiv" @click="changejob">{{ form2.jobName }}</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="jobNumber" label="工号">
								<el-input v-model="form2.jobNumber" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="repObjId" label="直接上级">
								<el-select
									v-model="form2.repObjId"
									placeholder="输入关键字"
									class="inputWidth"
									filterable
									remote
									reserve-keyword
									:remote-method="getHigherList"
									:loading="optLoading"
								>
									<el-option v-for="(item, i) in principalList" :key="i" :value="item.staffId" :label="item.staffName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="workingYears" label="司龄">
								<el-input v-model="form2.workingYears" class="inputWidth" readonly></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="hireForm" label="员工类型">
								<el-select v-model="form2.hireForm" placeholder="选择员工类型" class="inputWidth">
									<el-option v-for="(item, i) in hireFormList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="workProvinceCode" label="工作城市">
								<el-cascader
									filterable
									class="inputWidth"
									v-model="workcity"
									:options="provinceList"
									@change="handleChange2"
								></el-cascader>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="businessMailbox" label="工作邮箱">
								<el-input v-model="form2.businessMailbox" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="extensionNo" label="分机号">
								<el-input v-model="form2.extensionNo" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<!-- <el-row>
						<el-col :span="12">
							<el-form-item prop="postWage" label="岗位工资">
								<el-input v-model="form2.postWage" class="inputWidth" onkeyup="value=value.replace(/[^\d^\.]/g,'')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="performanceWage" label="绩效工资">
								<el-input v-model="form2.performanceWage" class="inputWidth" onkeyup="value=value.replace(/[^\d^\.]/g,'')"></el-input>
							</el-form-item>
						</el-col>
					</el-row> -->
					<!-- <el-row>
						<el-col :span="12">
							<el-form-item prop="bankCardNo" label="银行卡号">
								<el-input v-model="form2.bankCardNo" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="bankDeposit" label="开户行">
								<el-input v-model="form2.bankDeposit" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row> -->
					<el-row>
						<el-col :span="12">
							<el-form-item prop="workAddress" label="工作地址">
								<el-input v-model="form.workAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="socialSecurityAccount" label="社保账号">
								<el-input v-model="form2.socialSecurityAccount" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item prop="accumulationFund" label="公积金账号">
								<el-input v-model="form2.accumulationFund" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="hpfProvinceCode" label="社保公积金城市">
								<el-cascader
									class="inputWidth"
									filterable
									v-model="hpfcity"
									:options="provinceList"
									@change="handleChange"
								></el-cascader>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :offset="11" style="margin-top: 10px">
							<el-button @click="act = 1">上一步</el-button>
							<el-button @click="handleClick">保存</el-button>
							<el-button type="primary" @click="handleSubmit()">{{ '提交' }}</el-button>
						</el-col>
					</el-row>
				</div>
			</el-form>
		</section>
		<el-dialog title="职位体系" :visible.sync="dialogTableVisible" width="70%" top="5vh" center>
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item label="职族" style="margin-right: 20px">
					<el-input v-model="searchFamilyName" size="small" placeholder="请输入职族名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职类" style="margin-right: 20px">
					<el-input v-model="searchClassName" size="small" placeholder="请输入职类名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职位" style="margin-right: 20px">
					<el-input v-model="searchOfficeName" size="small" placeholder="请输入职位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位" style="margin-right: 20px">
					<el-input v-model="searchJobName" size="small" placeholder="请输入岗位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" style="margin-right: 20px">
					<el-button @click="getFamilyList" type="primary" size="small" icon="el-icon-search">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="gridData" height="400" @current-change="handleCurrentChange" highlight-current-row>
				<el-table-column property="familyName" label="职族"></el-table-column>
				<el-table-column property="className" label="职类"></el-table-column>
				<el-table-column property="subclassName" label="职类子类"></el-table-column>
				<el-table-column property="officeName" label="职位"></el-table-column>
				<el-table-column property="officeCode" label="职级代码"></el-table-column>
				<el-table-column property="jobName" label="岗位"></el-table-column>
				<el-table-column property="officeLevel" label="职级"></el-table-column>
			</el-table>
			<div style="text-align: right; margin-top: 10px">
				<el-pagination
					background
					@current-change="handleSysPageChange"
					:current-page="systemPageNo"
					:page-size="systemPageSize"
					layout="total, prev, pager, next"
					:total="systemTotal"
				>
				</el-pagination>
			</div>
			<div slot="footer" style="clear: both; text-align: center">
				<el-button @click="cancelSys">取 消</el-button>
				<el-button type="primary" @click="getSystem">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
// import { orgAddDraftStaff, DraftStaffInfo } from '@/api/workbench';
import { getPositionLevelList } from '@/api/staffSystem';
import treeselect from '@/components/treeSelect/treeSelect';
import * as api from '@/api/workbench';

export default {
	// props: { isApproval: { type: Boolean } },
	components: { treeselect },
	data() {
		return {
			act: 1,
			optionProps: {
				value: 'id',
				label: 'costCenterName',
				checkStrictly: true
			},
			form: {
				staffInfoId: '',
				staffId: '',
				resumeId: this.$route.query.resumeid,
				name: '',
				subName: '',
				maritalStatus: '',
				identityNum: '',
				birthday: '',
				age: '',
				sex: '',
				areaCode: '86',
				phoneNumber: '',
				highestEducation: '',
				mailbox: '',
				nativePlace: '',
				homeAddress: '',
				politicalStatus: '',
				emergencyContact: '',
				nationality: '',
				emergencyContactPhone: '',
				registeredResidenceAddress: '',
				workAddress: '',
				certificateAddress: '',
				emergencyContactRelation: '',
				emergencyContactPhone2: '',
				emergencyContactRelation2: '',
				emergencyContact2: '',
				certificateValidity: '',
				longTermEffective: false
			},
			form2: {
				entryTime: '',
				businessMailbox: '',
				turnPositiveTime: '',
				status: '',
				hireForm: '',
				jobNumber: '',
				orgId: '',
				// positionId: '',
				jobEtc: 'S',
				centerId: '',
				repObjId: '',
				workProvinceCode: '',
				workCityCode: '',
				firstWorkTime: '',
				hpfProvinceCode: '',
				hpfCityCode: '',
				levelAndCodeId: '',
				officeLevel: '',
				officeCode: '',
				officeName: '',
				subordinate: '',
				jobId: '',
				jobName: '',
				baseWage: '',
				performanceWage: '',
				postWage: '',
				// officeCode: '',
				duty: '',
				business: '',
				probationPeriod: '',
				planTurnPositiveTime: '',
				workingYears: '',
				extensionNo: '',
				bankCardNo: '',
				bankDeposit: '',
				socialSecurityAccount: '',
				accumulationFund: ''
			},
			pickerOptions: {
				disabledDate(time) {
					return (
						time.getTime() <
						new Date(new Date().setMonth(new Date().getMonth() == 0 ? 12 : new Date().getMonth() - 1)).setDate(1) - 86400 * 1000
					);
				}
			},
			// turnPositivePickerOptions:{
			//   disabledDate: time => {
			//     if(this.form2.entryTime){
			//       return time.getTime() < new Date(this.form2.entryTime)-(86400*1000)
			//     }else {
			//       return time.getTime() < new Date().getTime()-(86400*1000)
			//     }
			//   }
			// },
			firstWorkTimePickerOptions: {
				disabledDate: (time) => {
					if (this.form.birthday) {
						return (
							time.getTime() < new Date(this.form.birthday).setFullYear(Number(this.form.birthday.split('-')[0]) + 16) - 86400 * 1000 ||
							time.getTime() > new Date().getTime()
						);
					}
					return time.getTime() > new Date().getTime() - 86400 * 1000;
				}
			},
			deps: this.$store.state.setData.depsList,
			depsId: '',
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			jobItem: {},
			maritalStatusList: [],
			sourcesList: [],
			sexList: [],
			experienceList: [],
			highestEducationList: [],
			politicalStatusList: [],
			staffStatusList: [],
			hireFormList: [],
			managementRanksList: [],
			principalList: this.$store.state.setData.principalList,
			costList: this.$store.state.setData.centerList,
			provinceList: window.$cityList,
			nationalOptions: window.$nationalList,
			hpfcity: [],
			workcity: [],
			positionList: [],
			dialogTableVisible: false,
			searchFamilyName: '',
			searchClassName: '',
			searchOfficeName: '',
			searchJobName: '',
			systemPageNo: 1,
			systemPageSize: 20,
			systemTotal: 0,
			gridData: [],
			rules: {
				name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				identityNum: [
					{ required: true, message: '请输入身份证号码', trigger: 'blur' },
					{
						pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
						message: '证件号码格式有误！',
						trigger: 'blur'
					}
				],
				phoneNumber: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				],
				// emergencyContactPhone2: [{ required: true, message: '请输入紧急联系人电话', trigger: 'blur' }],
				certificateAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
				maritalStatus: [{ required: true, message: '请选择婚姻状态', trigger: 'change' }],
				mailbox: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
				highestEducation: [{ required: true, message: '请选择学历', trigger: 'change' }],
				homeAddress: [{ required: true, message: '请输入家庭住址', trigger: 'blur' }],
				nativePlace: [{ required: true, message: '请输入籍贯', trigger: 'blur' }],
				emergencyContact: [{ required: true, message: '请输入紧急联系人', trigger: 'blur' }],
				emergencyContactPhone: [{ required: true, message: '请输入紧急联系人电话', trigger: 'blur' }],
				politicalStatus: [{ required: true, message: '请选择政治面貌', trigger: 'change' }],
				registeredResidenceAddress: [{ required: true, message: '请输入户口所在', trigger: 'blur' }],
				workAddress: [{ required: true, message: '请输入工作地址', trigger: 'blur' }],
				nationality: [{ required: true, message: '请输入民族', trigger: 'blur' }],
				certificateValidity: [{ required: true, message: '请输入', trigger: 'blur' }],
				emergencyContactRelation: [{ required: true, message: '请输入', trigger: 'blur' }]
			},
			rules2: {
				// entryTime: [{ required: true, message: '请选择入职时间', trigger: 'change' }],
				// turnPositiveTime: [{ required: true, message: '请选择转正时间', trigger: 'change' }],
				businessMailbox: [
					//   { required: true, message: '请输入邮箱', trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱', trigger: ['blur', 'change'] }
				],
				// status: [{ required: true, message: '请选择状态', trigger: 'change' }],
				// hireForm: [{ required: true, message: '请选择聘用形式', trigger: 'change' }],
				jobNumber: [{ required: true, message: '请输入工号', trigger: 'blur' }],
				orgId: [{ required: true, message: '请选择组织部门', trigger: 'change' }],
				// officeCode: [{ required: true, message: '请选择对应职位代码', trigger: 'change' }],
				repObjName: [{ required: true, message: '请选择', trigger: 'change' }],
				// level: [{ required: true, message: '请选择对应职级', trigger: 'change' }],
				// positionId: [{ required: true, message: '请选择对应职位', trigger: 'change' }],
				jobId: [{ required: true, message: '请选择对应岗位', trigger: 'change' }],
				// positionId: [{ required: true, message: '请选择职务', trigger: 'change' }],
				jobEtc: [{ required: true, message: '请选择职等', trigger: 'change' }],
				centerId: [{ required: true, message: '请选择成本中心', trigger: 'change' }],
				repObjId: [{ required: false, message: '请选择汇报对象', trigger: 'change' }],
				// workProvinceCode: [{ required: true, message: '请选择工作城市', trigger: 'change' }],
				workCityCode: [{ required: true, message: '请选择工作城市', trigger: 'change' }],
				// firstWorkTime: [{ required: true, message: '请选择时间', trigger: 'change' }],
				// hpfProvinceCode: [{ required: true, message: '请选择社保城市', trigger: 'change' }],
				hpfCityCode: [{ required: true, message: '请选择社保公积金城市', trigger: 'change' }],
				subordinate: [{ required: true, message: '请输入下属量', trigger: 'blur' }],
				hireForm: [{ required: true, message: '请选择', trigger: 'blur' }]
			},
			certificateValidityList: [
				{ dictId: '5', dictName: '5年' },
				{ dictId: '10', dictName: '10年' },
				{ dictId: '20', dictName: '20年' },
				{ dictId: '99', dictName: '长期' }
			],
			probationPeriodList: [
				{ dictId: '0', dictName: '无试用期' },
				{ dictId: '1', dictName: '1个月' },
				{ dictId: '2', dictName: '2个月' },
				{ dictId: '3', dictName: '3个月' },
				{ dictId: '4', dictName: '4个月' },
				{ dictId: '5', dictName: '5个月' },
				{ dictId: '6', dictName: '6个月' }
			],
			staffId: '',
			optLoading: false
		};
	},
	computed: {
		relationshipList() {
			return this.$store.state.app.dict.filter((item) => item.groupId == 'relationship');
		}
	},
	mounted() {
		// this.queryPosition();
		const mapArr = [
			{ name: 'sourcesList', groupId: 'fromType' },
			{ name: 'sexList', groupId: 'sex' },
			{ name: 'experienceList', groupId: 'experience' },
			{ name: 'highestEducationList', groupId: 'highestEducation' },
			{ name: 'maritalStatusList', groupId: 'maritalStatus' },
			{ name: 'politicalStatusList', groupId: 'politicalStatus' },
			{ name: 'staffStatusList', groupId: 'status' },
			{ name: 'hireFormList', groupId: 'hireForm' },
			{ name: 'managementRanksList', groupId: 'managementRanks' }
		];

		mapArr.forEach((val) => {
			this[val.name] = this.$store.state.app.dict.filter((item) => {
				if (val.name == 'staffStatusList') {
					if (item.groupId == 'status' && item.dictId != '3') {
						return item.groupId == val.groupId;
					}
				} else {
					return item.groupId == val.groupId;
				}
			});
		});
		this.getFamilyList();
		if (this.$route.query.id) {
			api.DraftStaffInfo({ id: this.$route.query.id }).then((res) => {
				if (res._responseStatusCode == 0) {
					// eslint-disable-next-line guard-for-in
					for (const key1 in res) {
						for (const key in this.form) {
							if (key == key1 && res[key1]) {
								this.form[key] = res[key1];
							}
						}
						for (const key2 in this.form2) {
							if (key2 == key1 && res[key1]) {
								this.form2[key2] = res[key1];
							}
						}
					}
					this.workcity = [this.form2.workProvinceCode, this.form2.workCityCode];
					this.hpfcity = [this.form2.hpfProvinceCode, this.form2.hpfCityCode];
					// this.form2.positionId = String(this.form2.positionId);
					this.form2.centerId = String(this.form2.centerId);
					this.form2.orgId = String(this.form2.orgId);
					this.form.longTermEffective = String(this.form.certificateValidity) === '0';
					this.form.certificateValidity = this.form.certificateValidity?.split('|') || [];
					this.depsId = this.form2.orgId;
					if (this.form2?.jobId) {
						this.form2.jobId = String(this.form2.jobId);
					}
				}
			});
		}
	},
	methods: {
		// 直接上级
		getHigherList(query) {
			this.optLoading = true;
			api.selectPrincipal({ request: { staffName: query } })
				.then((res) => {
					this.principalList = res;
					this.optLoading = false;
				})
				.catch(() => {
					this.optLoading = false;
				});
		},
		toBack() {
			this.$router.go(-1);
		},
		checkLong() {
			this.form.certificateValidity = '';
		},
		handleClick() {
			if (!this.form.name) {
				this.$refs.form.validateField('name');
			} else {
				const _a = { ...this.form };

				// 修改 证件有效期
				if (Array.isArray(_a.certificateValidity)) {
					_a.certificateValidity = _a.certificateValidity?.join('|');
				}
				if (_a.longTermEffective) {
					_a.certificateValidity = '0';
					this.$delete(_a, 'longTermEffective');
				}

				const _b = {
					...this.form2,
					centerId: typeof this.form2.centerId === 'string' ? this.form2.centerId : this.form2.centerId[this.form2.centerId.length - 1]
				};
				const _c = Object.assign(_a, _b);

				// if (_c.jobId) {
				// 	_c.jobId = _c.jobId;
				// }
				api.orgAddDraftStaff({ ..._c, saveType: 1 }).then((res) => {
					if (res._responseStatusCode == 0) {
						this.form.staffInfoId = res.staffInfoId;
						this.form.staffId = res.staffId;
						this.$message.success('保存成功');

						this.act = 2;
						this.$refs.form2.clearValidate();
					}
				});
			}
		},
		next() {
			api.queryDepartment({ request: {} })
				.then((res) => {
					this.deps = res;
					this.$store.commit('SET_DEPT', res);
				})
				.catch(() => {});
			api.selectPrincipal({ request: {} })
				.then((res) => {
					this.$store.commit('SET_PRINCIPALLIST', res);
					this.principalList = res;
				})
				.catch(() => {});
			api.costCenter({ request: {} })
				.then((res) => {
					this.costList = res;
					this.$store.commit('SET_CENTERLIST', res);
				})
				.catch(() => {});
			this.act = 2;
		},
		prev() {},
		changejob() {
			this.dialogTableVisible = true;
		},
		handleSubmit() {
			this.form2.postWage = this.form2.postWage || 0;
			this.$refs['form2'].clearValidate();
			this.$refs['form2'].validate((valid) => {
				if (valid) {
					this.$confirm(`即将录入当前员工信息，您确认${this.form.name}信息是否无误?`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
						.then(() => {
							const _a = { ...this.form };

							// 修改 证件有效期
							if (Array.isArray(_a.certificateValidity)) {
								_a.certificateValidity = _a.certificateValidity?.join('|');
							}
							if (_a.longTermEffective) {
								_a.certificateValidity = '0';
								this.$delete(_a, 'longTermEffective');
							}

							const _b = {
								...this.form2,
								centerId:
									typeof this.form2.centerId === 'string'
										? this.form2.centerId
										: this.form2.centerId[this.form2.centerId.length - 1]
							};
							const _c = Object.assign(_a, _b);

							// if (_c.jobId) {
							// 	_c.jobId = _c.jobId;
							// }
							api.orgAddDraftStaff({ ..._c, saveType: 2 }).then((res) => {
								if (res._responseStatusCode == 0) {
									this.$message.success('操作成功');
									this.$router.go(-1);
								}
							});
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},
		handleChange(val) {
			this.form2.hpfProvinceCode = val[0];
			this.form2.hpfCityCode = val[1];
		},
		handleChange2(val) {
			this.form2.workProvinceCode = val[0];
			this.form2.workCityCode = val[1];
		},
		getFamilyList() {
			const params = {
				orgId: this.form2.orgId,
				familyName: this.searchFamilyName,
				className: this.searchClassName,
				officeName: this.searchOfficeName,
				jobName: this.searchJobName,
				startTime: '',
				endTime: '',
				pageNo: this.systemPageNo,
				pageSize: this.systemPageSize
			};

			getPositionLevelList(params).then((res) => {
				this.systemTotal = res.totalNum;
				this.gridData = res.list;
			});
		},
		handleCurrentChange(val) {
			this.currentRow = val;
		},
		handleSysPageChange(val) {
			this.systemPageNo = val;
			this.getFamilyList();
		},
		cancelSys() {
			this.currentRow = [];
			this.dialogTableVisible = false;
		},
		getSystem() {
			if (!this.currentRow) {
				this.$message.error('请选择职级体系信息');
				return;
			}
			this.jobItem = this.currentRow;
			// eslint-disable-next-line guard-for-in
			// for (const key in this.form2) {
			// 	// eslint-disable-next-line guard-for-in
			// 	for (const key1 in this.jobItem) {
			// 		if (key === key1 && this.jobItem[key1]) {
			// 			this.form2[key] = this.jobItem[key1];
			// 			console.log(key, 'keykeykeykeykey');
			// 			if (this.form2.orgId) {
			// 				this.depsId = this.form2.orgId;
			// 			}
			// 		}
			// 		// if (key === 'positionId' && this.jobItem['id']) {
			// 		// 	this.form2['positionId'] = this.jobItem['id'];
			// 		// }
			// 	}
			// }
			const checkKeys = ['orgId', 'officeLevel', 'officeCode', 'officeName', 'jobId', 'jobName'];

			checkKeys.forEach((key) => {
				this.form2[key] = this.jobItem[key];
				if (key === 'orgId') {
					this.depsId = this.form2.orgId;
				}
			});
			this.dialogTableVisible = false;
		},
		getValue2(eve) {
			const checkKeys = ['officeLevel', 'officeCode', 'officeName', 'jobId', 'jobName'];

			checkKeys.forEach((key) => {
				this.form2[key] = '';
			});
			this.jobItem = {};
			this.depsId = this.form2.orgId = eve.id ? eve.id : '';
			this.jobItem.orgName = eve.label ? eve.label : '';
			// 选择组织 刷新职级体系
			this.getFamilyList();
		},
		// 获取职务 (dev#隐藏职务体系，后续不再用到)
		// queryPosition () {
		//   api.queryPosition({
		//     request: {}
		//   }).then((res) => {
		//     this.positionList = res;
		//     this.$store.commit("SET_POSITIONLIST", res);
		//   }).catch(() => {
		//   });
		// },
		getBirthdayFromIdCard(idCard) {
			let birthday = '';

			// eslint-disable-next-line no-eq-null
			if (idCard != null && idCard != '') {
				if (idCard.length == 15) {
					birthday = `19${idCard.substr(6, 6)}`;
				} else if (idCard.length == 18) {
					birthday = idCard.substr(6, 8);
				}

				birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-');
			}
			return birthday;
		},
		getAge(identityCard) {
			const len = `${identityCard}`.length;

			if (len == 0) {
				return '0';
			}
			if (len != 15 && len != 18) {
				// 身份证号码只能为15位或18位其它不合法
				return '';
			}

			let strBirthday = '';

			if (len == 18) {
				// 处理18位的身份证号码从号码中得到生日和性别代码
				strBirthday = `${identityCard.substr(6, 4)}/${identityCard.substr(10, 2)}/${identityCard.substr(12, 2)}`;
			}
			if (len == 15) {
				strBirthday = `19${identityCard.substr(6, 2)}/${identityCard.substr(8, 2)}/${identityCard.substr(10, 2)}`;
			}
			// 时间字符串里，必须是“/”
			const birthDate = new Date(strBirthday);

			const nowDateTime = new Date();

			let age = nowDateTime.getFullYear() - birthDate.getFullYear();
			// 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1

			if (
				nowDateTime.getMonth() < birthDate.getMonth() ||
				(nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
			) {
				age--;
			}
			return age;
		},
		getSex(identityCard) {
			if (identityCard.length == 18) {
				return identityCard.charAt(16) % 2 == 0 ? '2' : '1';
			}
			if (identityCard.length == 15) {
				return identityCard.charAt(14) % 2 == 0 ? '2' : '1';
			}
			return '';
		},
		getPlanTime() {
			const { entryTime, probationPeriod } = this.form2;

			if (entryTime) {
				// eslint-disable-next-line prefer-const
				let [year, month, date] = entryTime.split('-');

				if (probationPeriod) {
					const targetMonth = Number(month) + Number(probationPeriod);

					if (targetMonth > 12) {
						year++;
						month = targetMonth % 12;
					} else {
						month = targetMonth;
					}
					this.$set(this.form2, 'planTurnPositiveTime', `${year}-${month < 10 ? `0${month}` : month}-${date}`);
				}
				const workingTime = new Date().getTime() - new Date(entryTime).getTime();

				if (workingTime > 0) {
					const workingYears = (Math.round(workingTime / 100 / 3600 / 24 / 365) / 10).toString();

					this.$set(this.form2, 'workingYears', workingYears);
				} else {
					this.$set(this.form2, 'workingYears', '');
				}
			} else {
				this.$set(this.form2, 'planTurnPositiveTime', '');
			}
		},
		statusChange(e) {
			if (e === '1') {
				this.form2.probationPeriod = '';
				this.probationPeriodList = this.probationPeriodList.map((val) => {
					if (val.dictId === '0') {
						val.disabled = true;
					}
					return val;
				});
				this.form2.turnPositiveTime = '';
			} else {
				this.probationPeriodList = this.probationPeriodList.map((val) => {
					if (val.dictId === '0') {
						val.disabled = '';
					}
					return val;
				});
			}
			if (e === '2') {
				const { entryTime } = this.form2;

				this.form2.probationPeriod = '0';
				this.form2.turnPositiveTime = entryTime;
			}
		}
	},
	watch: {
		'form.identityNum': {
			deep: true,
			handler(newVal) {
				this.form.birthday = this.getBirthdayFromIdCard(newVal);
				this.form.age = this.getAge(newVal);
				this.form.sex = this.getSex(newVal);
			}
		}
	}
};
</script>
<style lang="scss">
.interviewInfo {
	.recordBox {
		padding: 20px;
		margin-top: 10px;
		background-color: #fff;
	}
	.inputDiv {
		width: 70%;
		height: 40px;
		padding: 0 15px;
		background-color: #fff;
		cursor: pointer;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
	}
}
.sections-container {
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 30px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.sections-container {
	border-radius: 4px;
	padding: 40px 60px;
	background-color: #fff;
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
</style>
<style lang="scss" scoped>
.formIteClass {
	::v-deep.el-form-item__label {
		line-height: 20px;
		margin-top: 6px;
	}
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
.section-wrap {
	::v-deep.el-range-separator {
		padding: 0;
		width: 14px;
	}
}
</style>
